import React, { useState, useEffect } from "react";
import { Display } from "./utility/Display";
import { Games } from "./Games";
import { Library } from "./Library";
import { libraries } from "../resources/libraries";
import { Food } from "./Food";
import { Art } from "./Art";
import { Influence } from "./Influence";
import { Visuals } from "./Visuals";
import { SocialLinks } from "./SocialLinks";
import { About } from "./About";
import styled from "styled-components";
import Box from "ui-box";
import { Typography } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Face from "../images/face.png";

export const Page = styled(({ className, ...props }) => {
  const MOBILE_SCREEN_SIZE = 720;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MOBILE_SCREEN_SIZE
  );

  const handleResize = () => {
    if (window.innerWidth < MOBILE_SCREEN_SIZE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <Router>
      <Box className={className} {...props}>
        <Box className="header">
          <Box className="nameface">
            <img className="face" src={Face} alt={"A glitched face"} />
            <Box>
              <Typography variant={isMobile ? "h3" : "h1"}>
                Anthony Tešija
              </Typography>
              <Typography variant={isMobile ? "subtitle1" : "h5"} gutterBottom>
                Developer · Designer · Experiences · Visuals
              </Typography>
              <SocialLinks className="social" />
            </Box>
          </Box>
        </Box>
        <Box className="needsname">
          <Box className="nav">
            <Link to="/">
              <Typography variant="body1" gutterBottom>
                About & Current Work
              </Typography>
            </Link>
            <Link to="/visuals">
              <Typography variant="body1" gutterBottom>
                Live Visuals
              </Typography>
            </Link>
            <Link to="/games">
              <Typography variant="body1" gutterBottom>
                Games
              </Typography>
            </Link>
            <Link to="/games/localmultiplayer">
              <Typography className="subnavitem" variant="body1" gutterBottom>
                Local Multiplayer
              </Typography>
            </Link>
            <Link to="/games/mentalhealth">
              <Typography className="subnavitem" variant="body1" gutterBottom>
                Mental Health
              </Typography>
            </Link>
            <Link to="/games/art">
              <Typography className="subnavitem" variant="body1" gutterBottom>
                Art / Interaction
              </Typography>
            </Link>
            <Link to="/food">
              <Typography variant="body1" gutterBottom>
                Food
              </Typography>
            </Link>
            <Link to="/art">
              <Typography variant="body1" gutterBottom>
                Art
              </Typography>
            </Link>
            <Link to="/libraries">
              <Typography variant="body1" gutterBottom>
                Developer Libraries
              </Typography>
            </Link>
            {/* <Link to="/influence">
              <Typography variant="body1" gutterBottom>
                Influence
              </Typography>
            </Link> */}
          </Box>

          <Box className="content">
            <Switch>
              <Route path="/games/localmultiplayer">
                <Games filterType="multiplayer" />
              </Route>
              <Route path="/games/mentalhealth">
                <Games filterType="mentalhealth" />
              </Route>
              <Route path="/games/art">
                <Games filterType="art" />
              </Route>
              <Route path="/games">
                <Games />
              </Route>
              <Route path="/libraries">
                {libraries.map((library) => (
                  <Display>
                    <Library library={library} />
                  </Display>
                ))}
              </Route>
              <Route path="/visuals">
                <Visuals />
              </Route>
              <Route path="/food">
                <Food />
              </Route>
              <Route path="/art">
                <Art />
              </Route>
              <Route path="/influence">
                <Influence />
              </Route>
              <Route path="/">
                <About />
              </Route>
            </Switch>
          </Box>
        </Box>
      </Box>
    </Router>
  );
})`
  .header {
    margin-left: 16px;
    text-align: left;
  }

  .face {
    object-fit: contain;
    margin-right: 32px;
    width: 180px;
  }

  .nameface {
    display: flex;
    margin-bottom: 32px;
  }

  .needsname {
    display: flex;
    width: 100%;
  }

  .nav {
    margin-left: 32px;
    margin-right: 32px;
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 512px;
  }

  .subnavitem {
    margin-left: 16px;
  }

  .content {
    max-width: 600px;
    min-width: 500px;
    margin-right: 16px;
  }

  .social {
    margin-bottom: 16px;
  }

  @-ms-viewport {
    width: device-width;
  }
`;
