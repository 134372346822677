import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { art } from "../resources/art";
import { ArtPiece } from "./ArtPiece";

export const Art = styled(({ className, ...props }) => {
  return (
    <Box className={className} {...props}>
      {art.map((a) => (
        <ArtPiece art={a} />
      ))}
    </Box>
  );
})``;
