import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Typography } from "@material-ui/core";
import { Link } from "@material-ui/core";
import ReactPlayer from "react-player";
import { Lightbox } from "./utility/Lightbox";

import Unity from "../images/gamestores/unity.png";

const StoreMap = {
  unity: Unity,
};

export const Library = styled(({ className, library, ...props }) => {
  return (
    <Box className={className} {...props}>
      <Typography variant="h6">{library.title}</Typography>
      <Typography variant="caption" gutterBottom>
        {library.year} - {library.platforms}
      </Typography>
      <Box className="imagedescription">
        <Box>
          {library.screenshots &&
            library.screenshots.map((image) => (
              <Lightbox>
                <img
                  className="screenshotImage"
                  src={process.env.PUBLIC_URL + "/images/libraries/" + image}
                  alt=""
                />
              </Lightbox>
            ))}
        </Box>
        <Box className="description">
          <Typography variant="body1" gutterBottom>
            {library.description}
          </Typography>
          {library.links &&
            library.links.map((link) => (
              <Typography variant="body1" gutterBottom>
                <Link href={link.link}>{link.name}</Link>
              </Typography>
            ))}
        </Box>
      </Box>
      <Box className="videos">
        {library.videos &&
          library.videos.map((video) => (
            <Box className="video">
              <ReactPlayer
                className="gameVideo"
                url={video.link}
                controls={true}
                width="180px"
                height="100px"
              />
              <Typography variant="caption" gutterBottom>
                {video.caption}
              </Typography>
            </Box>
          ))}
      </Box>
      <Box className="links">
        {library.storeLinks &&
          library.storeLinks.map((storeLink) => (
            <Box className="link">
              <Link href={storeLink.link}>
                <img
                  className="gameStoreLink"
                  src={StoreMap[storeLink.store]}
                  alt=""
                />
              </Link>
            </Box>
          ))}
      </Box>
    </Box>
  );
})`
  .screenshotImage {
    width: 200px;
    margin-bottom: 8px;
  }

  .gameStoreLink {
    width: 140px;
  }

  .imagedescription {
    display: flex;
    margin-top: 8px;
  }

  .videos {
    display: flex;
    margin: 8px;
  }

  .video {
    margin-right: 8px;
  }

  .description {
    margin-left: 8px;
  }

  .links {
    display: flex;
  }

  .link {
    margin-right: 8px;
  }
`;
