import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Typography } from "@material-ui/core";
import { Display } from "./utility/Display";
import ReactPlayer from "react-player";

export const Visuals = styled(({ className, ...props }) => {
  return (
    <Box className={className} {...props}>
      <Display>
        <Typography variant="h6">Live Visuals</Typography>
        <Typography gutterBottom variant="body1">
          Using Unity and custom controllers I've built my own VJ software that
          I use to create music videos, TikToks, and custom live visuals for
          bands and DJs. I've played the iconic SF venues The Independent,
          Rickshaw Stop, Gray Area, and Bottom of the Hill with the bands
          OVRGRWN and Your Fearless Leader. I'm the resident VJ for the
          underground venue The Spacement and I organized and ran visuals for
          Fanime Con in San Jose with an average of 34,000 attendees.
        </Typography>
        <Typography gutterBottom variant="body1">
          Here is some of my recent work:
        </Typography>
        <Box className="video">
          <ReactPlayer
            url="https://youtu.be/-XDE8MIVmRw"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Playing a sold out show live at the Independent in SF with OVRGRWN
          </Typography>
        </Box>
        <Box className="video">
          <ReactPlayer
            url="https://youtu.be/km_Y4_-4v8Y?si=P9L_cb9SWXCYYyg1"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Compilation of visuals created in 2023
          </Typography>
        </Box>
        <Box className="video">
          <ReactPlayer
            url="https://youtu.be/04oLdg8CloM?si=QGxuZSPcawsOKII9"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Music video for Kaeto as a member of Covert Frequency
          </Typography>
        </Box>
        <Box className="video">
          <ReactPlayer
            url="https://youtu.be/rHVnaf0Lyfs?si=0e9oAYqHi7zsbYsV"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Visual created for a small GDC party for the release of Last Seen
            Online
          </Typography>
        </Box>
        {/* <Box className="video">
          <ReactPlayer
            url="https://www.youtube.com/live/tkncBfmgs7g?si=BeJOeEEEXJIHJ4Gs&t=5362"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Live with Jah's Tin at Resident Monthly Electronic at Noisebridge in
            SF
          </Typography>
        </Box> */}
        <Box className="video">
          <ReactPlayer
            url="https://youtu.be/4-ywCpLSEI8?si=NvLT9s6f6_6cPztv"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Compilation of visuals for a DJ night at The Spacement SF
          </Typography>
        </Box>
        <Box className="video">
          <ReactPlayer
            url="https://youtu.be/tj4-mg9IX3w?si=8UQDnalvfp6foqEJ"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Preview of a visual for the band OVRGWN
          </Typography>
        </Box>
        <Box className="video">
          <ReactPlayer
            url="https://youtu.be/DroAk-pAaNk?si=zl6swAWD1DynfNLv"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            Visuals for a burning man camp
          </Typography>
        </Box>
        <Box className="video">
          <ReactPlayer
            url="https://youtube.com/shorts/7lXQdzEkBjg"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            A TikTok created for 3Kelevs, a local DJ and house/disco producer
          </Typography>
        </Box>
        <Box className="video">
          <ReactPlayer
            url="https://youtube.com/shorts/Pvko2hwMOv8"
            controls={true}
            width="500px"
          />
          <Typography variant="caption" gutterBottom>
            A TikTok created for 3Kelevs, a local DJ and house/disco producer
          </Typography>
        </Box>
      </Display>
    </Box>
  );
})`
  .video {
    margin-bottom: 32px;
  }
`;
