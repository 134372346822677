export const games = [
  // {
  //   title: "Echo Chess",
  //   year: 2024,
  //   platforms: "Web",
  //   description:
  //     "A daily chess puzzle where you are what you eat! I was in charge of prototyping, game design, and development of the level editor. In only four weeks we had a polished and fully featured level editor that allowed players to make, save, and share puzzles easily with each other. I also prototyped the first version of an unanounced feature.",
  //   links: [
  //     {
  //       name: "Website",
  //       link:
  //         "https://echochess.com/",
  //     },
  //     {
  //       name: "Level Editor",
  //       link: "https://echochess.com/playground"
  //     },
  //     {
  //       name: "Company Website",
  //       link: "https://www.echochunk.com/"
  //     }
  //   ],
  //   videos: [
  //     {
  //       caption: "Trailer",
  //       link: "https://youtu.be/H3fD5FbUv3c?si=55FxmEGnFf-O7Z1n",
  //     },
  //   ],
  //   screenshots: ["echochess.gif"],
  // },
    {
    title: "Echo Chess",
    year: 2024,
    platforms: "Web",
    description:
      "Game development and design for the daily chess puzzle game where you are what you eat!",
    links: [
      {
        name: "Website",
        link:
          "https://echochess.com/",
      }
    ],
    screenshots: ["echochess.png"],
  },
  {
    title: "Untitled Glitch Game",
    year: 2024,
    platforms: "Installation",
    description:
      "A ? player chaotic local multiplayer battle experience built inside of an audio reactive visualizer. Four players battle to collect the most gems by slapping each other while the crowd plays with a grid of 16 knobs and buttons that each effect the game in different ways. Buttons will cause mayhem on their corresponding space sometimes spawning new objects, calling all players to that position, spawning more copies of players, and more. Knobs can randomly modify visual effects and underlying attributes of the game like player speed, slap strength, hand size, amount of gems spawned, and more. Any number of people can play with these options live while players are battling which leads to a game? experience? Is it fun? Maybe? Is it pretty? Heck YEAH! Originally created for the Bring Your Own Beamer event at Gray Area in San Francisco CA",
    screenshots: ["glitchslap2.gif", "glitchslap2person.gif"],
    tags: ["multiplayer"],
    storeLinks: [
    ],
  },
  {
    title: "Jazz Heist",
    year: 2023,
    platforms: "Physical Puzzle",
    description:
      "The Jazz Heist is an in person escape room style set of puzzles built to be installed in offices and even spaces. It features a custom brain built in Unity that triggers hidden locks, lights, video, and sound all hidden in a physical phonebooth using COM ports. The game is played using an old rotary phone that was modified to send keyboard input to the machine and seamlessly plays videos used to unravel the mystery of a missing necklace in a jazzy 20's era heist.",
    screenshots: ["jazz.jpg", "jazz3.jpg"],
    links: [
      {
        name: "Website",
        link: "https://jazzheist.club/",
      },
    ],
  },
  {
    title: "Jump and Puzzle",
    year: 2022,
    platforms: "PC",
    description:
      "Jump and Puzzle is an asymmetrical cooperative puzzle platformer for two friends where one player builds the level to help the other player escape. The player building the level gets blocks Tetris-style and they help their friend by building ways for them to reach a key and finish the level. Blocks can contain bombs, enemies, ice blocks that freeze the map, amoeba blocks that slowly grow, flower blocks that shoot seeds and grow more blocks, bounce pads, spikes, and plenty more! Each level is a puzzle that starts off pre-built and has specific blocks that can spawn. Some require you to destroy structures, or plant a garden, or evade rising lava to help the platforming player reach the exit!",
    screenshots: ["jumpandpuzzlefriendgame.gif"],
    tags: ["multiplayer"],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/jump-and-puzzle",
      },
    ],
  },
  {
    title: "Plenty of Fish",
    year: 2021,
    platforms: "Web",
    description:
      "There are plenty of fish in the sea. Get back out there and treat everyone with love and kindness :)",
    learnings: ["Writing dialoge"],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/plenty-of-fish",
      },
    ],
    screenshots: ["plentyoffish.gif"],
  },
  {
    title: "Geo Monitor",
    year: 2021,
    platforms: "Web",
    description:
      "Reports of an angel hum from a machine buried deep underground",
    learnings: ["Layering shaders with camera clear off"],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/geo-monitor",
      },
    ],
    screenshots: ["geo.gif"],
    tags: ["art"],
  },
  {
    title: "Good Enough?",
    year: 2021,
    platforms: "Web",
    description:
      "Good Enough is an interaction about external pressure and personal choices. It touches on anxiety, growing up, and external pressure from the people around you. During brief gameplay you're asked to choose the person that everyone wants you to be but when trying to select any person they warp out of shape and disappear. When clicking to select who you should be it warps all the options, even those out of reach. Anxiously, you can hear a heartbeat speeding up and slowing down along with long deep breaths as if you're inside your own mind. This piece is a distillation of all the social pressure we deal with as we grow up and shows you that you'll never be able to become what others want you to be and through this bleak interaction you hopefully realize that you have to live your life for yourself to be truly happy.",
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/good-enough",
      },
    ],
    screenshots: ["goodenough.gif"],
    tags: ["art", "mentalhealth"],
  },
  {
    title: "Floating",
    year: 2021,
    platforms: "Web",
    description: "Floating is an interaction presented without description",
    learnings: ["Freya's Shapes library"],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/floating",
      },
    ],
    screenshots: ["floating.gif"],
    tags: ["art"],
  },
  {
    title: "Stretch B4U Race",
    year: 2021,
    platforms: "PC",
    description:
      'A sketch of a networked multiplayer 3D platforming action racing playground with collectathon and tag elements. This is best played with a game controller and a few friends. Made for Ludum Dare 48 with a theme of "Deeper and deeper"',
    learnings: ["Online multiplayer"],
    storeLinks: [
      {
        store: "itchio",
        link: "https://lunarsignals.itch.io/stretch-b4u-race",
      },
    ],
    screenshots: ["stretchb4urace.gif"],
    videos: [
      {
        caption: "Gameplay video",
        link: "https://youtu.be/8szWhOBX-0I",
      },
    ],
    tags: ["multiplayer"],
  },
  {
    title: "Craving",
    year: 2021,
    platforms: "Web",
    description:
      "Craving is a horror interaction about the cycle of never being able to meet expectations",
    learnings: ["Carrying particle systems between scenes"],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/craving",
      },
    ],
    screenshots: ["craving.gif"],
    tags: ["art", "mentalhealth"],
  },
  {
    title: "One More Multiverse",
    year: 2020,
    platforms: "Web",
    description:
      "One More Multiverse is an online virtual tabletop to play, share, and stream your next tabletop roleplaying game. With a massive and ever-growing pixel art library collection, in OMM create a level that changes as quickly as your story. Roleplay with thousands of character design options. Bring your world together with vibe filters, atmospheric effects, and thrilling animations in game and pull off a cinematic feeling for every session. For One More Multiverse I designed, implemented, and supported the combat system in a way that allows players to customize their own systems and abilities like attack types, weaknesses, animations, particles, timing, counter attacks, damage over time, statuses, targeting, and the composition of all these elements in an easy to use UI.",
    links: [
      {
        name: "Website",
        link:
          "https://multiverse.com/",
      }
    ],
    videos: [
      {
        caption: "Trailer",
        link: "https://youtu.be/jR4BKqpY4js?si=k_vKimGoyV5A6Pef",
      },
    ],
    screenshots: ["omm1.gif", "omm1.jpg", "omm2.jpg"],
    tags: ["multiplayer"],
  },
  {
    title: "GourMelee",
    year: 2019,
    platforms: "PC",
    description:
      "Chop, sear, and eat your way through a collection of organic minigames in GourMelee! Play with two to eight players in this local multiplayer party game where you share your controller with a friend to dominate the kitchen. Every minigame is made with cooperation in mind. Each player holds half of the controller working together with their other half to become the best chef in the kitchen! GourMelee is the perfect snack at a party, bust it out with the chips and dip!",
    learnings: [
      "ScriptableObjects in Unity",
      "Building game systems around ScriptableObjects",
      "Balancing a game with shared resources",
      "Making controller sharing fun",
    ],
    storeLinks: [
      {
        store: "steam",
        link: "https://store.steampowered.com/app/1081410/GourMelee/",
      },
      {
        store: "itchio",
        link: "https://antontesh.itch.io/gourmelee",
      },
    ],
    links: [
      {
        name: "Press coverage and review",
        link:
          "https://tophatmuffin.medium.com/pegbrje-gourmelee-and-miles-infernum-6630e33f7359",
      },
      {
        name: "Shown publically in Belgium",
        link: "https://www.quai10.be/gaming/lespacedexposition/",
      },
    ],
    screenshots: ["gourmelee.gif", "gourmelee2.gif"],
    videos: [
      {
        caption: "Trailer",
        link: "https://youtu.be/zYyuw5ZdBGg",
      },
      {
        caption: "Played by Funhaus",
        link: "https://youtu.be/dT4dSqM07Eo?t=266",
      },
      {
        caption: "Twitch @ E3 2019",
        link: "https://www.twitch.tv/videos/436936319?t=07h14m27s",
      },
    ],
    awards: ["Selected game at Indie Game Party at ACEN 2018"],
    tags: ["multiplayer"],
  },
  {
    title: "Down to Fly",
    year: 2019,
    platforms: "PC",
    description:
      "Smack! Watch out for the swatter while you mate with your friends to keep your fly family alive. Down to Fly is a one vs. six game about mating and the circle of life. Down to Fly was made in three days for TrainJam 2019 and shown off at GDC 2019. The theme was circuitous.",
    learnings: [
      "Building a custom dialog system piggy-backing off of my font system",
    ],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/down-to-fly",
      },
    ],
    screenshots: ["downtofly.gif"],
    tags: ["multiplayer"],
  },
  {
    title: "Reflection",
    year: 2018,
    platforms: "PC",
    description:
      "Reflection is a game about a point in my life where I made a lot of big changes and what I did to cope with them. I started journaling to handle my anxiety and help clear my head. It's something that I still do daily and helps me manage my thoughts. This game was made in 6 hours for Meditations, a game launcher that lets you play a new, short, and poetic game each day.",
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/reflection",
      },
    ],
    links: [
      { name: "Meditations Website", link: "http://meditations.games/" },
      {
        name: "Article about Reflection",
        link:
          "http://overlypositive.com/2019/01/27/27-365-meditation-games-27-blog-therapy/",
      },
      {
        name: "Video by Fangle Spangle",
        link: "https://www.youtube.com/watch?v=Wbgik_MLoUI",
      },
    ],
    screenshots: ["reflection.png"],
    tags: ["mentalhealth"],
  },
  {
    title: "OddEyeSee",
    year: 2018,
    platforms: "PC",
    description:
      "You must hide in a sea of eyes so that the other players don't know where you are. Then copy what the other eyes are doing to blend in. Finally you get to poke anyone that you think didn't blend in. If you're left alive you win! OddEyeSee was made for TrainJam 2018 and shown off at GDC 2018 and ICGJ 2019.",

    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/oddeyesee",
      },
    ],
    screenshots: ["oddeyesee.gif"],
    videos: [
      {
        caption: "Gameplay",
        link: "https://youtu.be/vPX8kjx1i3Y",
      },
    ],
    tags: ["multiplayer"],
  },
  {
    title: "Business Card Games",
    year: 2018,
    platforms: "Business Card",
    description:
      "I was making business cards for GDC and they were plain and boring. I decided to try to figure out a way to turn them into a game. My constraints were: The game pieces and rules had to fit on the card, they had to be multiplayer, and they had to involve other business cards somehow. These games are played by tearing the pieces off of my business card and flicking them around.",
    screenshots: ["businesscard.jpeg", "businesscard2.jpeg"],
    tags: ["multiplayer"],
  },
  {
    title: "Glitch Slap",
    year: 2017,
    platforms: "PC",
    description:
      "Glitch Slap is a 2D, platform based, multiplayer, party game that will have co-op and vs. modes. The game features arenas with different objectives where players slap objects, enemies, and each other to win. Vs. mode has arenas where players compete against each other along with game mutations like a virus that you pass between players by slapping each other and player location swapping. The co-op mode has arenas like boss battles and fighting enemy waves along with persistent power-ups that carry between the arenas.",
    learnings: ["Creating SpriteRenderer shaders in Unity"],
    screenshots: ["glitchslap.gif"],
    videos: [
      {
        caption: "Stugan 2017 application",
        link: "https://youtu.be/YQ3quxmn8IU",
      },
    ],
    tags: ["multiplayer"],
  },
  {
    title: "Heart Hop",
    year: 2017,
    platforms: "PC",
    description:
      "Heart Hop is an eight player, team based, battle game played with four controllers. There are two teams, light and dark. Each team either needs to step on all their enemy's hearts or on the button at the back of their base. Players share a controller with their opponent with each player holding one side of it. The game has a continuous 2 second countdown and when it says \"go!\" each player moves in the direction they're holding. If two players land on the same spot they bounce back to their original space. This allows for some team members to stay back and block their opponents from hitting their hearts. This game was made for Wolverine Soft's 48 hour game competition and won 1st place out of 10 entries. The theme was \"3 lives, 2 buttons, 1 moment\". Wolverine Soft is University of Michigan's game development club.",

    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/heart-hop",
      },
      {
        store: "download",
        link: "https://www.dropbox.com/s/rsa1eb09svkttcj/HeartHop.zip?dl=0",
      },
    ],
    links: [
      {
        name: "Article about the game jam",
        link:
          "https://cse.engin.umich.edu/stories/wolverine-softs-january-game-jam-produces-strong-games-three-winners",
      },
    ],
    screenshots: ["hearthop.gif"],
    awards: ["First Place"],
    tags: ["multiplayer"],
  },
  {
    title: "Boardgame",
    year: 2016,
    platforms: "Real Life",
    description:
      '"Boardgame" is a programming style game where you choose three moves hidden from your opponents and then reveal them in order. Players have the option to move one space, attack one space next to them, defend from attacks, and push a player one space away from them. The game is played on a six by six grid and placement of players and items is done using two dice to index into the board. Between rounds items will be randomly placed on the board, and then each dead player can place an item on the board.',

    screenshots: ["boardgame.jpg", "boardgame2.jpg"],
    tags: ["multiplayer"],
  },
  {
    title: "Depersonalize Me",
    year: 2016,
    platforms: "Web",
    description:
      "Depersonalize Me is a game about anxiety, depression, and depersonalization. I tried to capture how anxiety can take over my mind and how it's hard, but not impossible, to recover when it happens.",
    learnings: [
      "Use and tweak Unity shaders",
      "Modify Unity audio mixer from code",
    ],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/depersonalize-me",
      },
      {
        store: "download",
        link:
          "https://www.dropbox.com/s/qs5n49hwodriqt0/DepersonalizeMeWindows.zip?dl=0",
      },
    ],
    links: [
      {
        name: "Writeup of the game",
        link: "http://pajamagaming.blogspot.com/2017/02/browser-games-2.html",
      },
    ],
    screenshots: ["depersonalizeme.png", "depersonalizeme2.png"],
    videos: [
      {
        caption: "Playthrough and commentary by Spacey Ghost",
        link: "https://youtu.be/yNNYt7it4EI",
      },
    ],
    tags: ["mentalhealth"],
  },
  {
    title: "Sad Bunny Man",
    year: 2016,
    platforms: "PC",
    description:
      'Made in one hour for One Hour Game Jam with a theme of "Two Buttons".',
    storeLinks: [
      {
        store: "download",
        link:
          "https://www.dropbox.com/s/lduztnxi3v0legn/Sad%20Bunny-man.zip?dl=0",
      },
    ],
    screenshots: ["sadbunnyman.gif"],
  },
  {
    title: "Life Is Short",
    year: 2016,
    platforms: "PC and Mac",
    description:
      "Life is Short is a four player battle platformer where you strive to be the last player alive. A giant worm is destroying the world and you and your friends have to escape it! Along the way you can pick up and throw items to stun players or collect the powerups inside them. The powerups increase your stun duration, throw distance, jump height, and run speed. Use them to your advantage to take out your friends and be the last one alive! This game was made for the Capital City Film Festival Fortnight Game Jam 2016 which is a state wide game development competition in Michigan.",
    learnings: ["How to use the Unity audio mixer"],
    storeLinks: [
      {
        store: "itchio",
        link: "https://antontesh.itch.io/life-is-short",
      },
      {
        store: "download",
        link:
          "https://www.dropbox.com/s/8nieewpoo6ncb3v/Life%20is%20Short.zip?dl=0",
      },
    ],
    links: [
      {
        name: "Capital City Film Fest",
        link:
          "https://capitalcityfilmfest.com/event/fortnight-screening-awards-2016",
      },
    ],
    screenshots: ["lifeisshort.gif"],
    videos: [
      {
        caption: "Twitch @ E3 2019",
        link: "https://www.twitch.tv/videos/436936319?t=07h23m33s",
      },
      { caption: "Played by Stumpt", link: "https://youtu.be/Ry1nYDOVYfo" },
    ],
    awards: ["Second Place", "Crowd Favorite"],
    tags: ["multiplayer"],
  },
  {
    title: "Eyeconic",
    year: 2016,
    platforms: "Android",
    description:
      "EYEConic is a clean and minimal looking bullet-hell style game for mobile phones. You drag a character around to keep it away from endless waves of enemies that get faster as time goes on. Each enemy has a unique movement pattern that can be learned and avoided. While dodging enemies you collect orbs to increase your score. When you die your score is saved to an online leaderboard where you can see how you rank globally or against your friends.",
    learnings: ["Use simple movement to create interesting enemy patterns"],
    storeLinks: [
      {
        store: "android",
        link:
          "https://play.google.com/store/apps/details?id=com.ATeshGames.EYEConic",
      },
    ],
    screenshots: ["eyeconic.png"],
  },
  {
    title: "Natrual Perfection",
    year: 2015,
    platforms: "PC and Mac",
    description:
      'A fast paced, local, four player free for all where the losers evolve between rounds. Players have the basic ability to shoot and dash attack to kill each other. After each round the losers evolution is chosen based on how they battled, died, and many other factors just like natural selection would work (On a much faster time scale). Evolutions like exploding bullets, bouncing bullets, armor, spiky body, longer dash attack, and many others make up to eighteen possible evolutions that produce thousands of combinations. Will you be the one that will carry on the species? Natural Perfection was made with a team for a local two week long game jam with the theme of "Evolution".',
    learnings: ["Working with a team with different backgrounds"],
    screenshots: ["natrualperfection.gif"],
    tags: ["multiplayer"],
  },
  {
    title: "Lost in Thought",
    year: 2015,
    platforms: "Web",
    description:
      "Lost in Thought is about dreaming, loving, and losing. The idea I was trying to convey was when you dream and come up with something special that has meaning to you. After waking up you remember it fondly for a short time and then those memories fade away. Losing those memories is the permanent death of whatever feelings or ideas came from them. This is the first game I've made that was built around a story. Lost in Thought was made for both ColorJam0 and PermaDeathJam. This game was also feature 641/1000 in @moshboy 1000 gamedevs.",
    learnings: ["Creating a story with no words", "Unity animation system"],
    storeLinks: [
      {
        store: "gamejolt",
        link: "http://gamejolt.com/games/other/lost-in-thought/58805/",
      },
    ],
    links: [
      { name: "PermaDeathJam", link: "http://jams.gamejolt.io/permadeathjam" },
      { name: "ColorJam0", link: "http://jams.gamejolt.io/colorjam0" },
      {
        name: "@moshboy 1000 gamedevs",
        link:
          "https://twitter.com/moshboy/status/804809834247819264?s=20&t=YmjNmpw_YHbiJkhMPdVGXA",
      },
    ],
    screenshots: ["lostinthought.png"],
  },
  {
    title: "Eggplant Panic!",
    year: 2015,
    platforms: "Android",
    description:
      "That chef's fixin to prep ya, get out the way! Watch out for his knives or you'll get cut! This is why you should eat your veggies.",
    storeLinks: [
      {
        store: "android",
        link:
          "https://play.google.com/store/apps/details?id=com.ATeshGames.EggplantPanic",
      },
    ],
    screenshots: ["eggplantpanic.png"],
  },
  {
    title: "Jettison",
    year: 2015,
    platforms: "Android and iOS",
    description:
      "Jettison is a puzzle platformer that features a thirty level story mode and an impossibly difficult set of challenge levels designed to test your skills. You play as an astronaut escaping an exploding factory on the moon. The factory you're escaping has deadly traps including acid and timed lasers. The astronaut runs automatically and will turn when it bumps into a wall. The game is easy to learn and play, only requiring a touch to fire your jetpack. You have a small amount of fuel that will recharge when you are on the ground and deplete while using your jetpack. The challenge comes with maneuvering through hazardous traps while trying to conserve fuel. Each level has a key and collecting it will open the door to the next level. Beat all the levels to get to the escape pod and leave the moon!",
    learnings: [
      "Using external map editor",
      "Building a static website",
      "Releasing a game on Android and iOS",
      "Updating a game with more content",
    ],
    storeLinks: [
      {
        store: "android",
        link:
          "https://play.google.com/store/apps/details?id=com.ATeshGames.Jettison",
      },
      {
        store: "ios",
        link: "https://itunes.apple.com/app/id972913479",
      },
    ],
    links: [
      {
        name: "Game website",
        link: "http://atesija.github.io/jettison/jettison",
      },
    ],
    screenshots: ["jettison.gif"],
    videos: [
      {
        caption: "Release trailer",
        link: "https://youtu.be/MNvM2Di3G8Q",
      },
      {
        caption: "Jupi Plays Jettison",
        link: "https://youtu.be/XBvBdBJ7mIE",
      },
    ],
    awards: [
      "Over 4 star rating with almost 200 reviews and thousands of players",
    ],
  },
  {
    title: "Thug Buster",
    year: 2014,
    platforms: "Android",
    description:
      "Thug Buster is an homage to Hogan's Alley on the NES. Thugs and hostages pop up and you must tap all the thugs to eliminate them. Waiting too long or tapping a hostage will damage you and you have three attempts to get a high score.",
    learnings: ["Mobile development in Unity", "Touch controls"],
    storeLinks: [
      {
        store: "android",
        link:
          "https://play.google.com/store/apps/details?id=com.ATeshGames.ThugBuster",
      },
    ],
    screenshots: ["thugbuster.png"],
  },
  {
    title: "Galactic Planet Wrangler",
    year: 2014,
    platforms: "Web",
    description:
      "In Galactic Planet Wrangler your task is to round up as many planets as possible before crashing into the sun. Your controls consist of riding your asteroid as you barrel towards your impending doom or yelling YEEHAW! because you feel like it. Use your amazing abilities to collect as many planets and asteroids as you can before sacrificing them to the sun.",
    learnings: ["Vector graphics", "Minimap using another camera"],
    storeLinks: [
      {
        store: "gamejolt",
        link:
          "http://gamejolt.com/games/arcade/galactic-planet-wrangler/33164/",
      },
    ],
    screenshots: ["galacticplanetwrangler.png"],
    videos: [
      {
        caption: "Game preview",
        link: "https://youtu.be/hRXjoLWrguw",
      },
    ],
    awards: ["294th out of 1045 entries in Ludum Dare 30"],
  },
  {
    title: "Dizzy's World",
    year: 2014,
    platforms: "Web",
    description:
      "Dizzy's World is a puzzle platformer where you guide Dizzy through dangerous worlds to his home. Dizzy is pretty old so he can't jump like his famous platforming counterparts, but he does have vertigo and the world seems to shift around him. Use his nauseating power to bring him home! In each level you collect a key and get to a door to make it out. There are ten levels to guide Dizzy through before he can make it home. You can rotate the world in order to make it to hard to reach places.",
    learnings: ["Unity", "Render targets"],
    storeLinks: [
      {
        store: "gamejolt",
        link: "http://gamejolt.com/games/puzzle/dizzy-s-world/31664/",
      },
    ],
    links: [{ name: "GBJam3", link: "http://jams.gamejolt.io/gbjam3" }],
    screenshots: ["dizzysworld.png"],
    videos: [
      {
        caption: "Dizzy's World gameplay",
        link: "https://youtu.be/nd3QCeeZ4oc?t=348",
      },
    ],
    awards: ["84th out of 248 entries in GBJam3"],
  },
  {
    title: "Holy Split!",
    year: 2013,
    platforms: "PC",
    description:
      'Holy Split! is a game about a robot astronaut with a color based plasma rifle. Colored alien blobs are attacking you and you must shoot them with their own color to kill them. You only have red, blue, and yellow to shoot with. Shooting blobs of the secondary colors green, orange, and purple with one of the primary colors that go into them will split them into two primary aliens. Hitting any alien with a color not used to make it will cause it to split in two. Each color alien has a different AI that will try to trick you. Oh and they multiply over time if you leave them alone so watch out! Made for Wolverine Soft\'s 48 hour game competition with the theme "Separate".',
    learnings: ["Leading a team in a game jam"],
    storeLinks: [
      {
        store: "download",
        link: "https://www.dropbox.com/s/lubcoebptvdlt81/HolySplit.zip?dl=0",
      },
    ],
    screenshots: ["holysplit.png"],
    awards: ["3rd place out of 20 entries"],
  },
  {
    title: "Lazer Eyez",
    year: 2012,
    platforms: "PC",
    description:
      "Lazer Eyez was made as a school project using the Microsoft Kinect to help children with Autism. Some autistic people have trouble maintaining eye contact and the game tracked head and eye movement as the main input to help alleviate that. In the game you are a super hero saving the world from alien invaders. You use your laser vision to shoot down their ships and stop them from destroying cities. While the game may not have helped like we initially wanted, it was a great experience to try to alleviate some effects of Autism.",
    learnings: [
      "3D modeling with Blender and working with 3D",
      "Kinect integration",
    ],
    screenshots: ["lazereyez.png"],
  },
  {
    title: "Blast Lords",
    year: 2012,
    platforms: "Unreleased",
    description:
      "last Lords was my first ambitious project that played like a platformer version of Bomberman. It was four player local multiplayer with controller support. Each player had a set amount of lives and would spawn on a stage with each other. There were all sorts of items that could spawn randomly in the level. Items that changed the player's number of bombs, firepower, speed, health both positive and negative could all be found. The game featured a level editor and four tilesets to choose from when making them. There were a handful of levels included.",
    learnings: ["Platformer collisions and physics", "Local multiplayer"],
    screenshots: ["blastlords.png"],
    tags: ["multiplayer"],
  },
  {
    title: "Fired!",
    year: 2012,
    platforms: "PC",
    description:
      'Fired! is a game about sticking it to the man. This game was made for Wolverine Soft\'s 48 hour game competition with a theme of "The End".',
    learnings: ["Movement that isn't tile based", "Collision detection", "XNA"],
    screenshots: ["fired.png"],
  },
  {
    title: "Sneak Thief",
    year: 2011,
    platforms: "PC and Linux",
    description:
      "Make your way through this puzzle game collecting the gem on each level and avoiding all the traps. There are four items that can be found in each level that will help you on your way. There are also power generators in each level that activate lights and lasers. Sneak Thief comes with 50 levels and a level editor so you can make and share levels with your friends.",
    learnings: ["Animation using linear interpolation", "In-game level editor"],
    storeLinks: [
      {
        store: "download",
        link: "https://www.dropbox.com/s/gup0kvncgu1yngi/SneakThief.zip?dl=0",
      },
    ],
    screenshots: ["sneakthief.png"],
  },
  {
    title: "Blender",
    year: 2011,
    platforms: "PC and Linux",
    description:
      "Combine colored orbs to open doors in this perilous puzzle maze. Each colored orb opens it's corresponding colored door. The rainbow orbs allow you to combine your current colors to unlock new paths. This game was made for Wolverine Soft's 48 hour game competition with a theme of \"Combine\"",
    learnings: [
      "How to cut scope",
      "Animation by swapping sprites",
      "Keyboard and mouse input",
    ],
    storeLinks: [
      {
        store: "download",
        link: "https://www.dropbox.com/s/jc0ubnk7oh2k3is/Blender.zip?dl=0",
      },
    ],
    screenshots: ["blender.png"],
    awards: ["3rd place out of 16 entries"],
  },
  {
    title: "Lumberjack Zed Attack",
    year: 2010,
    platforms: "PC and Linux",
    description:
      "An arcade survival game about a burly lumberjack and his ax. The zombie apocalypse has finally reached the mountains where he lives and he must use his skills to survive. Zombies spawn from all corners of the screen and they can smell you. Use your trusty ax to cut down trees and lay traps using the wood. Rescue survivors along the way for bonus points! The zombies get faster over time so watch out!",
    learnings: [
      "Basic game input",
      "Rendering to a screen",
      "Game loop (input, update, draw)",
      "Reading levels from a file",
      "SDL",
    ],
    storeLinks: [
      {
        store: "download",
        link:
          "https://www.dropbox.com/s/bb5hsfo6mejna09/Lumberjack%20Zed%20Attack.zip?dl=0",
      },
    ],
    screenshots: ["lumberjackzedattack.png"],
  },
];

//process.env.PUBLIC_URL

/*
  {
    title: "",
    year: 1,
    platforms: "PC",
    description: "",
    learnings: ["", "",],
    storeLinks: [
      {
        store: "itchio",
        link: "",
      },
    ],
    links: [
      { name: "", link: "" },
    ],
    screenshots: ["",],
    videos: [
      {
        caption: "This is a video about...",
        link: "",
      },
    ],
    awards: ["",],
    tags: [""],
  },
*/
