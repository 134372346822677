import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

export const Display = styled(({ className, children, ...props }) => {
  return (
    <Paper elevation={5} className={className} {...props}>
      {children}
    </Paper>
  );
})`
  padding: 16px;
  margin-bottom: 32px;
`;
