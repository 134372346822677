import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Link } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Display } from "./utility/Display";
import { Lightbox } from "./utility/Lightbox";
import ReactPlayer from "react-player";

export const ArtPiece = styled(({ className, art, ...props }) => {
  return (
    <Box className={className} {...props}>
      <Display>
        <Typography variant="h6">{art.title}</Typography>
        <Typography variant="caption" gutterBottom>
          {art.year} - {art.medium}
        </Typography>
        <Box className="artinfo">
          <Box>
            <Lightbox>
              <img
                className="mainimage"
                src={process.env.PUBLIC_URL + "/images/art/" + art.mainImage}
                alt={""}
              />
            </Lightbox>
            {art.video && (
              <ReactPlayer
                className="gameVideo"
                url={art.video}
                controls={true}
                width="200px"
                height="140px"
              />
            )}
          </Box>
          <Box className={"artimages"}>
            {art.images?.map((imageName) => (
              <Lightbox>
                <img
                  className="image"
                  src={process.env.PUBLIC_URL + "/images/art/" + imageName}
                  alt={""}
                />
              </Lightbox>
            ))}
          </Box>
          <Box className="text">
            <Typography variant="body1" gutterBottom>
              {art.description}
            </Typography>
            {art.links?.map((link) => (
              <Typography variant="body1" gutterBottom>
                <Link href={link.link}>{link.name}</Link>
              </Typography>
            ))}
          </Box>
        </Box>
      </Display>
    </Box>
  );
})`
  .artinfo {
    display: flex;
  }

  .artimages {
    display: flex;
    flex-direction: column;
  }

  .mainimage {
    width: 200px;
    padding-right: 16px;
  }

  .image {
    width: 100px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  .text {
    display: flex;
    flex-direction: column;
  }
`;
