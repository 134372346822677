import React from "react";
import styled from "styled-components";
import { SRLWrapper } from "simple-react-lightbox";

export const Lightbox = styled(({ className, children, gallery, ...props }) => {
  const options = {
    buttons: {
      backgroundColor: "rgba(30,30,36,0.8)",
      showAutoplayButton: false,
      showCloseButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: gallery,
      showPrevButton: gallery,
      showThumbnailsButton: gallery,
    },
    thumbnails: {
      showThumbnails: gallery,
    },
  };

  return (
    <SRLWrapper className={className} {...props} options={options}>
      {children}
    </SRLWrapper>
  );
})``;
