export const food = {
  cookies: [
    {
      image: "cookies/cookiedarkchocolateespresso.jpg",
      description: "Dark chocolate espresso cookies",
    },
    {
      image: "cookies/cookiepackaged.jpg",
      description: "Cookies ready for sale",
    },
    {
      image: "cookies/cookiedarkmilklemon.jpg",
      description:
        "Dark chocolate, orange milk chocolate, and lemon poppyseed cookies",
    },
    {
      image: "cookies/cookiemm.jpg",
      description: "M&M cookies",
    },
    {
      image: "cookies/cookiechocolate.jpg",
      description: "Double chocolate cookies",
    },
    {
      image: "cookies/cookiesell.jpg",
      description: "Menu for cookie sales",
    },
  ],
  fermentation: [
    {
      image: "fermentation/fermentedplums.jpg",
      description: "Fermented plums using Noma's recipe",
    },
    {
      image: "fermentation/fermentedtomatos.jpg",
      description: "Fermented cherry tomatoes used in pastas and ramen",
    },
    {
      image: "fermentation/fermentedpickled.jpg",
      description:
        "Lacto fermented habanero garlic hotsauce, pickled bok choy and tomatoes, and kimchi",
    },
    {
      image: "fermentation/fermentedgreens.jpg",
      description:
        "Prep for lacto fermented pickles, okra, jalapeno slices, and bird's eye chilis",
    },
    {
      image: "fermentation/fermentedfoods.jpg",
      description: "Fermented watermelon, plums, pickles, and giardiniera",
    },
    {
      image: "fermentation/fermentedhotsauces.jpg",
      description: "Lacto fermented habanero and garlic hot sauce",
    },
  ],
  recipes: [
    {
      image: "recipes/breakfastsandwich.jpg",
      description: "A solid breakfast sandwich",
    },
    {
      image: "recipes/fish.jpg",
      description: "My recipe for salmon",
    },
    {
      image: "recipes/bananabread.jpg",
      description: "A modified version of the Bon Appetit banana bread recipe",
    },
    {
      image: "recipes/wontonsoup.jpg",
      description: "Wonton and shrimp soup",
    },
    {
      image: "recipes/watermelonjuice.jpg",
      description: "Yum! Refreshing!",
    },
  ],
  misc: [
    {
      image: "avocadotoast.jpg",
      description: "",
    },
    {
      image: "bananabread.jpg",
      description: "",
    },
    {
      image: "bibimbap.jpg",
      description: "",
    },
    {
      image: "birria.jpg",
      description: "",
    },
    {
      image: "blueberrycheesecakecut.jpg",
      description: "",
    },
    {
      image: "blueberrycheesecakeslice.jpg",
      description: "",
    },
    {
      image: "blueberrycheesecakewhole.jpg",
      description: "",
    },
    {
      image: "breakfastsandwich.jpg",
      description: "",
    },
    {
      image: "breakfastsandwich2.jpg",
      description: "",
    },
    {
      image: "butterchicken.jpg",
      description: "",
    },
    {
      image: "dal.jpg",
      description: "",
    },
    {
      image: "dalmustardbrussels.jpg",
      description: "",
    },
    {
      image: "dandannoodles.jpg",
      description: "",
    },
    {
      image: "deepfriedsmelt.jpg",
      description: "",
    },
    {
      image: "foragedsteak.jpg",
      description: "",
    },
    {
      image: "fruitsalad.jpg",
      description: "",
    },
    {
      image: "gaipadkrapow.jpg",
      description: "",
    },
    {
      image: "mushroomsoup.jpg",
      description: "",
    },
    {
      image: "okonomiyaki.jpg",
      description: "",
    },
    {
      image: "pancakes.jpg",
      description: "",
    },
    {
      image: "pastachinese.jpg",
      description: "",
    },
    {
      image: "pastaonionfig.jpg",
      description: "",
    },
    {
      image: "pastapesto.jpg",
      description: "",
    },
    {
      image: "pastaprep.jpg",
      description: "",
    },
    {
      image: "pastascrapsmushroom.jpg",
      description: "",
    },
    {
      image: "pbj.jpg",
      description: "",
    },
    {
      image: "pickledjars.jpg",
      description: "",
    },
    {
      image: "pickledprep.jpg",
      description: "",
    },
    {
      image: "pickledprep2.jpg",
      description: "",
    },
    {
      image: "pizza.jpg",
      description: "",
    },
    {
      image: "ramenbeef.jpg",
      description: "",
    },
    {
      image: "ramenpho.jpg",
      description: "",
    },
    {
      image: "ramentonkotsu.jpg",
      description: "",
    },
    {
      image: "roastedcauliflower.jpg",
      description: "",
    },
    {
      image: "roastedchickensausage.jpg",
      description: "",
    },
    {
      image: "sandwichfigbrie.jpg",
      description: "",
    },
    {
      image: "searedsalmon.jpg",
      description: "",
    },
    {
      image: "searedsalmonpeas.jpg",
      description: "",
    },
    {
      image: "shrimp.jpg",
      description: "",
    },
    {
      image: "strawberrycheesecake.jpg",
      description: "",
    },
    {
      image: "strawberrycheesecakeplain.jpg",
      description: "",
    },
    {
      image: "syrup.jpg",
      description: "",
    },
    {
      image: "tempurafish.jpg",
      description: "",
    },
    {
      image: "tempuramushroom.jpg",
      description: "",
    },
    {
      image: "tsukemenitalian.jpg",
      description: "",
    },
    {
      image: "wintermelonstew.jpg",
      description: "",
    },
    {
      image: "wontonsoup.jpg",
      description: "",
    },
    {
      image: "wontonsoupprawn.jpg",
      description: "",
    },
  ],
};
