export const art = [
  {
    title: "Wine labels",
    year: "2023",
    medium: "Label/Sticker",
    description:
      "Commisioned to create a classic label for a meritage and a fun label for a chardonnay",
    mainImage: "wine/red.jpg",
    images: ["wine/white.jpeg"],
  },
  {
    title: "Good Bird pop up logo",
    year: "2023",
    medium: "Logo",
    description:
      "Commisioned to create a logo for a friend's food pop up and brand",
    mainImage: "goodbird/menu.jpg",
    images: ["goodbird/logo.jpg"],
  },
  {
    title: "Stickers created for friends",
    year: "2023",
    medium: "Sticker",
    description: "Misc stickers created over the last year",
    mainImage: "sticker23/pepper.jpg",
    images: ["sticker23/jackb.jpg"],
  },
  {
    title: "Pillows",
    year: "2022",
    medium: "Velvet",
    description:
      "I got a yellow couch and wanted fun pillows so I made purple velvet and cowhide pillows",
    mainImage: "pillow/pillow.jpg",
  },
  {
    title: "Shirt with extended sleeves",
    year: "2022",
    medium: "Cotton and linnen",
    description:
      "The sleeves were too short on this shirt and it cost money to return so I used the bag they came in as fabric to make the sleeves longer",
    mainImage: "extendedsleeveshirt/1.jpg",
    images: ["extendedsleeveshirt/2.jpg"],
  },
  {
    title: "Laptop Holder",
    year: "2021",
    medium: "Felt and thread",
    description:
      "I kept putting my laptop on the floor and I didn't want to step on it so I made this and it hangs from my bed now",
    mainImage: "laptopholder/finished.jpg",
    images: ["laptopholder/design.jpeg", "laptopholder/process.jpg"],
  },
  {
    title: "Shelves",
    year: "2021",
    medium: "Wood and iron",
    description:
      "I needed shelves so I designed and built some. Look at all the things that I needed to put on them",
    mainImage: "shelves/finished.jpg",
    images: ["shelves/design1.jpg", "shelves/render.png", "shelves/space.jpg"],
  },
  {
    title: "Frog mask",
    year: "2020",
    medium: "Felt and thread",
    description: "A hand sewn mask for humans",
    mainImage: "frogmask/frogmask.jpg",
    images: [
      "frogmask/frogmaskmaking.jpg",
      "frogmask/design.jpg",
      "frogmask/design2.jpg",
    ],
  },
  {
    title: "Shirt with sleeve gap",
    year: "2020",
    medium: "Cloth and thread",
    description:
      "My left arm gets hot sometimes so I wanted a single vent specifically for it",
    mainImage: "sleevegapshirt/sleeveconnectormain.jpg",
    images: [
      "sleevegapshirt/design.jpg",
      "sleevegapshirt/sleeveconnectorbuild2.jpg",
      "sleevegapshirt/sleeveconnectoralt2.jpg",
    ],
  },
  {
    title: "Disco Grips",
    year: "2020",
    medium: "Digital interaction",
    description:
      "An album cover and interactive art piece used for the music videos of an album by 3Kelves",
    mainImage: "discogrips/album.png",
    video: "https://youtu.be/AjbPQTO7L9s",
    links: [
      {
        name: "Bandcamp album",
        link: "https://3kelves.bandcamp.com/album/disco-grips",
      },
      {
        name: "3Kelves",
        link: "https://3kelves.com/",
      },
    ],
  },
  {
    title: "Nautical Nik's album covers",
    year: "2019-2021",
    medium: "Digital",
    description:
      "Album covers used for multiple songs and singles by Nautical Nik and Nautpop",
    mainImage: "nauticalnikalbums/single.png",
    images: [
      "nauticalnikalbums/rainbowsunset.png",
      "nauticalnikalbums/nautpop.png",
    ],
    links: [
      {
        name: "Nautical Nik",
        link: "https://n.nik.band/",
      },
      {
        name: "Nautpop",
        link:
          "https://open.spotify.com/artist/4OKZyuNIVvb76Ycj6HLSnx?si=0bCy2qx9RruaeexDUM7Keg&dl_branch=1",
      },
    ],
  },
  {
    title: "Stickers for Nautical Nik and Jerrypop",
    year: "2020-2021",
    medium: "Digital and sticker",
    description:
      "Stickers made for promotional use by Nautical Nik for his album and Jerrypop for his popcorn and youtube popcorn series",
    mainImage: "nikjerstickers/sticker2.jpg",
    links: [
      {
        name: "Jerrypop",
        link: "https://www.youtube.com/channel/UC_3oErdLxGZgeTf8nrwsD3A",
      },
      {
        name: "Nautical Nik",
        link: "https://n.nik.band/",
      },
    ],
  },
  {
    title: "Art prompt generator",
    year: "2020",
    medium: "Code",
    description:
      "A website that generates random art prompts for when you've got drawers block. Built using Tracery, a language generation tool",
    mainImage: "artprompt/1.png",
    links: [
      {
        name: "Art Generator",
        link: "https://promptsforart.netlify.app/",
      },
      {
        name: "Tracery",
        link: "https://tracery.io/",
      },
    ],
  },
  {
    title: "Plant hangers",
    year: "2019",
    medium: "Rope and wood",
    description: "Hangers designed and made for the corners of a room",
    mainImage: "planthangers/hanger.jpg",
    images: [
      "planthangers/hanger2.jpg",
      "planthangers/design.jpg",
      "planthangers/design2.jpg",
    ],
  },
  {
    title: "Show poster for the band Your Fearless Leader",
    year: "2019",
    medium: "Digital",
    description:
      "Show poster for the band Your Fearless Leader in San Francisco",
    mainImage: "yflposter/poster.png",
    images: ["yflposter/design.jpg"],
    links: [
      {
        name: "Your fearless Leader",
        link: "https://www.facebook.com/YFLband",
      },
    ],
  },
  {
    title: "Camp themed hackathon stickers",
    year: "2019",
    medium: "Digital and sticker",
    description:
      "Stickers designed for Apartment List's yearly hackathon. The theme was summer camp so each sticker was a badge you earned by doing an event",
    mainImage: "alistbadges/onlaptop.jpg",
    images: ["alistbadges/design.png", "alistbadges/stickers.jpg"],
  },
  {
    title: "Business cards",
    year: "2018",
    medium: "Digital and paper",
    description:
      "My business cards that each have unique games on them. The games require you tear up a portion of the card to play leaving you with the pieces, rules, and my contact info. I thought it would be funny to see someone tear up my business card after giving it to them and that's where the design came from",
    mainImage: "businesscard/businesscard.jpeg",
    images: ["businesscard/businesscard2.jpeg"],
  },
  {
    title: "Boardgame",
    year: "2018",
    medium: "Digital and cardstock",
    description:
      '"Boardgame" is a programming style game where you choose three moves hidden from your opponents and then reveal them in order. Players have the option to move one space, attack one space next to them, defend from attacks, and push a player one space away from them. The game is played on a six by six grid and placement of players and items is done using two dice to index into the board. Between rounds items will be randomly placed on the board, and then each dead player can place an item on the board.',
    mainImage: "boardgame/playing2.jpg",
    images: ["boardgame/design.png", "boardgame/playing.jpg"],
  },
  {
    title: "Slide guitar",
    year: "2014",
    medium: "Wood",
    description:
      "This guitar was build out of a cigarbox and a broken cello neck. Using an acoustic guitar pickup and guitar strings created a unique slide guitar that I performed with in college.",
    mainImage: "slideguitar/1.jpg",
    images: ["slideguitar/2.jpg"],
  },
];

// raspi
// signs for gourmelee
// costumes
// wall paintings like lemons and colors
