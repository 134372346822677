import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Display } from "./utility/Display";
import { Game } from "./Game";
import { games } from "../resources/games";

export const Games = styled(({ className, filterType, ...props }) => {
  let gamesToDisplay = games;
  if (filterType) {
    gamesToDisplay = games.filter((game) => game?.tags?.includes(filterType));
  }

  return (
    <Box className={className} {...props}>
      {gamesToDisplay.map((game) => (
        <Display>
          <Game game={game} />
        </Display>
      ))}
    </Box>
  );
})``;
