import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Typography } from "@material-ui/core";
import { Display } from "./utility/Display";

export const Influence = styled(({ className, ...props }) => {
  return (
    <Box className={className} {...props}>
      <Display>
        <Typography gutterBottom variant="body1">
          Influence for my work comes from many places, here are some
          highlights:
        </Typography>

        <Typography variant="h6">Games</Typography>
        <Typography gutterBottom variant="body1">
          Super Mario World, Banjo Kazooie, The Legend of Zelda
          Link's Awakening, Pikmin, Bomberman Hero, Super Mario Sunshine,
          Spelunky
        </Typography>

        <Typography variant="h6">Comics</Typography>
        <Typography gutterBottom variant="body1">
          Uzumaki, Fridge Zone, Beautiful Darkness, anything published by Silver
          Sprocket, Gleem, EX.Mag 1-4, and anything else published by Peow
        </Typography>

        <Typography variant="h6">Music</Typography>
        <Typography gutterBottom variant="body1">
          MF DOOM, The Dandy Warhols, Death Grips, Tobacco,
          Yung Bae, Earl Sweatshirt, Destroy Boys, Boys Age
        </Typography>

        <Typography variant="h6">Anime</Typography>
        <Typography gutterBottom variant="body1">
          Neon Genesis Evangelion, Cowboy Bebop, FLCL, Shin Chan, Beck, Hunter X
          Hunter, Akira
        </Typography>
      </Display>
    </Box>
  );
})``;
