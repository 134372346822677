import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Typography } from "@material-ui/core";
import { Display } from "./utility/Display";
import { food } from "../resources/food";
import { Lightbox } from "./utility/Lightbox";

export const Food = styled(({ className, ...props }) => {
  return (
    <Box className={className} {...props}>
      <Display>
        <Typography variant="h6">Cookies</Typography>
        <Typography gutterBottom variant="body1">
          I've been working on this cookie recipe for years and came up with a
          high butter content cookie that comes out perfect every time. I use
          the base recipe to experiment with flavors like orange milk chocolate,
          lemon honey sichuan peppercorn, dark chocolate espresso, raspberry
          white chocolate, and milk chocolate toasted walnut.
        </Typography>
        <Box className="foodcontainer">
          {food.cookies.map((cookie) => (
            <Box className="food">
              <Lightbox>
                <img
                  className="foodimage"
                  src={process.env.PUBLIC_URL + "/images/food/" + cookie.image}
                  alt={cookie.description}
                ></img>
              </Lightbox>
              <Typography variant="caption" gutterBottom>
                {cookie.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Display>
      <Display>
        <Typography variant="h6">Fermentation</Typography>
        <Box className="foodcontainer">
          {food.fermentation.map((ferment) => (
            <Box className="food">
              <Lightbox>
                <img
                  className="foodimage"
                  src={process.env.PUBLIC_URL + "/images/food/" + ferment.image}
                  alt={ferment.description}
                ></img>
              </Lightbox>
              <Typography variant="caption" gutterBottom>
                {ferment.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Display>
      <Display>
        <Typography variant="h6">Recipes</Typography>
        <Typography gutterBottom variant="body1">
          Sometimes I doodle my recipes
        </Typography>
        <Box className="foodcontainer">
          {food.recipes.map((recipe) => (
            <Box className="food">
              <Lightbox>
                <img
                  className="foodimage"
                  src={process.env.PUBLIC_URL + "/images/food/" + recipe.image}
                  alt={recipe.description}
                ></img>
              </Lightbox>
              <Typography variant="caption" gutterBottom>
                {recipe.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Display>
      <Display>
        <Typography variant="h6">Selected dishes</Typography>
        <Box className="foodcontainer">
          {food.misc.map((dish) => (
            <Box className="smallfood">
              <Lightbox>
                <img
                  className="foodimage"
                  src={process.env.PUBLIC_URL + "/images/food/" + dish.image}
                  alt={dish.description}
                ></img>
              </Lightbox>
              <Typography variant="caption" gutterBottom>
                {dish.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Display>
    </Box>
  );
})`
  .foodimage {
    width: 100%;
  }

  .food {
    margin-bottom: 16px;
    width: 200px;
  }

  .foodcontainer {
    margin-top 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .smallfood {
    margin-bottom: 16px;
    width: 160px;
  }
`;
